export function getImg(img){
    let res;
    try{
        res = require(`./img/${img.trim()}`);
    }catch(e){
        console.log('Not found image', e);
        let defaultImg = './img/logo.jpg';
        res = require(`${defaultImg}`);
    }
    return res;
}

export function isMobile() {
    return window.matchMedia("(pointer: coarse)").matches;
}