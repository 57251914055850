import Img1 from './bathroom/20-web-or-mls-DSC06617.jpg';
import Img2 from './bathroom/35-web-or-mls-DSC06840.jpg';
import Img3 from './bathroom/37-web-or-mls-DSC06845.jpg';
import Img4 from './bathroom/IMG_8631.jpg';
import Img5 from './bathroom/web_1-27.jpg';
import Img6 from './bathroom/W5390484_23.jpg';
import Img7 from './bathroom/W5390484_22.jpg';
import Img8 from './bathroom/W5390484_27.jpg';
import Img9 from './bathroom/W5390484_28.jpg';
import Img10 from './bathroom/W5390484_38.jpg';
import Img11 from './bathroom/IMG_1436.JPG';
import Img12 from './bathroom/IMG_0063.HEIC';
import Img13 from './bathroom/IMG_0257.JPG';
import Img14 from './bathroom/IMG_0261.JPG';
import Img15 from './bathroom/IMG_0262.JPG';
import Img16 from './bathroom/IMG_0267.JPG';
import Img17 from './bathroom/IMG_0301.JPG';
import Img18 from './bathroom/IMG_0303.JPG';
import Img19 from './bathroom/IMG_0304.JPG';
import Img20 from './bathroom/IMG_0305.JPG';
import Img21 from './bathroom/IMG_0319.JPG';
import Img22 from './bathroom/IMG_0320.JPG';
import Img23 from './bathroom/IMG_0321.JPG';
import Img24 from './bathroom/IMG_0322.JPG';
import Img25 from './bathroom/IMG_0324.JPG';
import Img26 from './bathroom/IMG_0347.JPG';
import Img27 from './bathroom/IMG_0364.JPG';
import Img28 from './bathroom/IMG_0367.JPG';
import Img29 from './bathroom/IMG_0373.JPG';
import Img30 from './bathroom/IMG_0400.JPG';
import Img31 from './bathroom/IMG_0401.JPG';
import Img32 from './bathroom/IMG_0402.JPG';
import Img33 from './bathroom/IMG_0403.JPG';
import Img34 from './bathroom/IMG_0404.JPG';
import Img35 from './bathroom/IMG_0405.JPG';
import Img36 from './bathroom/IMG_0406.JPG';
import Img37 from './bathroom/IMG_0407.JPG';
import Img38 from './bathroom/IMG_0415.HEIC';
import Img39 from './bathroom/IMG_0416.HEIC';
import Img40 from './bathroom/IMG_0417.HEIC';
import Img41 from './bathroom/IMG_0430.HEIC';
import Img42 from './bathroom/IMG_0431.HEIC';
import Img43 from './bathroom/IMG_0610.HEIC';
import Img44 from './bathroom/IMG_0702.HEIC';
import Img45 from './bathroom/IMG_0703.HEIC';
import Img46 from './bathroom/IMG_0704.HEIC';
import Img47 from './bathroom/IMG_0707.HEIC';
import Img48 from './bathroom/IMG_1335.JPG';



export const bathroom = [
    Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11,
    Img12, Img13, Img14, Img15, Img16, Img17, Img18, Img19,
    Img20, Img21, Img22, Img23, Img24, Img25, Img26, Img27, Img28, Img29,
    Img30, Img31, Img32, Img33, Img34, Img35, Img36, Img37, Img38, Img39,
    Img40, Img41, Img42, Img43, Img44, Img45, Img46, Img47, Img48
]