import React, {Fragment} from 'react';
import './Menu.css';
import {Link} from "react-router-dom";
import {getImg} from "../../../Helper";


class Menu extends React.Component {
    render() {
        return (
            <Fragment>
                <nav role="navigation" className="primary-navigation">
                    <img className={'nav-logo'} src={getImg('logo.jpg')} alt={'logo'}/>
                    <ul className={'nav-table'}>
                        <li><Link to={'/'} className={'nav-link'}>Home</Link></li>
                        <li><Link to={'/about'} className={'nav-link'}>About Us</Link></li>

                        <li>Services
                            <ul style={{zIndex: 1}} className="dropdown">
                                <li><Link to={'/kitchen-renovations'} className={'nav-link'}>Kitchens</Link></li>
                                <li><Link to={'/bathroom-renovations'} className={'nav-link'}>Bathrooms</Link></li>
                                <li><Link to={'/basement-renovations'} className={'nav-link'}>Basements</Link></li>
                                <li><Link to={'/house-renovations'} className={'nav-link'}>Whole House</Link></li>
                            </ul>
                        </li>
                        <li><Link to={'/portfolio'} className={'nav-link'}>Gallery</Link></li>
                        <li><Link to={'/contact'} className={'nav-link'}>Contact Us</Link></li>
                    </ul>
                </nav>
            </Fragment>
        );
    }
}

export default Menu;