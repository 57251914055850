import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {getImg} from "../../Helper";
import './CardType.css';


export default function CardType(props) {
    let useStyles = makeStyles({});

    if (props.large) {
        if (window.innerWidth > 880) {
            useStyles = makeStyles({
                root: {
                    width: '40%',
                    //maxHeight: '50vh',
                    display: 'inline-block',
                    margin: '2.5% 5%',
                },
                centered: {
                    justifyContent: "center"
                },
                button: {
                    width: '15vw',
                    //height: '5vw',
                },
                text: {
                    fontSize: '1em'
                },
                title: {
                    textAlign: 'center',
                    fontSize: '1.5em'
                }
            });
        } else {
            useStyles = makeStyles({
                root: {
                    width: '70%',
                    //maxHeight: '50vh',
                    display: 'inline-block',
                    margin: '2.5% 15%',
                },
                centered: {
                    justifyContent: "center"
                },
                button: {
                    width: '15vw',
                    height: '5vh',
                },
                text: {
                    fontSize: '0.8em'
                },
                title: {
                    textAlign: 'center',
                    fontSize: '1.2em'
                }
            });
        }
    } else if (window.innerWidth > 880) {
        useStyles = makeStyles({
            root: {
                width: '20vw',
                maxHeight: '40vh',
                display: 'inline-block',
                marginLeft: '2vw',
                marginRight: '2vw',
            },
            centered: {
                justifyContent: "center"
            },
            button: {
                width: '15vw',
                height: '5vh',
            },
            text: {
                fontSize: '1em'
            },
            title: {
                textAlign: 'center',
                fontSize: '1.5em'
            }
        });
    } else {
        useStyles = makeStyles({
            root: {
                width: '40vw',
                maxHeight: '500px',
                display: 'inline-block',
                marginLeft: '4vw',
                marginRight: '4vw',
                marginTop: '1vh',
            },
            centered: {
                justifyContent: "center"
            },
            button: {
                width: '15vw',
                height: '5vh',
            },
            text: {
                fontSize: '0.8em'
            },
            title: {
                textAlign: 'center',
                fontSize: '1.2em'
            }
        });
    }


    let classes = useStyles();
    return (
        <Fragment>
            <Card className={classes.root}>
                <CardActionArea>

                    <CardMedia
                        style={props.large ? {height: 200} : {}}
                        component="img"
                        alt="main image"
                        height={window.innerWidth > 820 ? '100' : '200'}
                        image={getImg(props.image)}
                        title="test main image"
                    />

                    <CardContent>
                        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                            {props.title}
                        </Typography>
                        <Typography className={classes.text} variant="body2" color="textSecondary" component="p">
                            {props.text}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <div>
                    <CardActions className={classes.centered}>
                        <Link className={'not-underlined'} to={props.link}>
                            <Button style={{height: 'auto'}} className={'card-btn'} variant="outlined" color="primary">
                                {props.btnText ? props.btnText : 'Read More'}
                            </Button>
                        </Link>
                    </CardActions>
                </div>
            </Card>
        </Fragment>
    );
}