import Img1 from './basement/21-web-or-mls-DSC06635.jpg';
import Img2 from './basement/46-web-or-mls-DSC06942.jpg';
import Img3 from './basement/47-web-or-mls-DSC06952.jpg';
import Img4 from './basement/web_1-35.jpg';
import Img5 from './basement/web_1-37.jpg';
import Img6 from './basement/IMG_0255.JPG';
import Img7 from './basement/IMG_0258.JPG';
import Img8 from './basement/IMG_0259.JPG';
import Img9 from './basement/IMG_0293.JPG';
import Img10 from './basement/IMG_0317.JPG';
import Img11 from './basement/IMG_0315.JPG';
import Img12 from './basement/W5390484_32.jpg';
import Img13 from './basement/W5390484_33.jpg';
import Img14 from './basement/W5390484_34.jpg';
import Img15 from './basement/W5390484_35.jpg';
import Img16 from './basement/W5390484_36.jpg';


export const basement = [
    Img2, Img4, Img5, Img12, Img13, Img14, Img15, Img16, Img6, Img7, Img8, Img9, Img10, Img11, Img1, Img3
]