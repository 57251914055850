import React, {useEffect} from 'react';
import './Projects.css';
import '@fortawesome/fontawesome-free/css/all.css';
import CardType from "../CardType/CardType";

const Projects = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    return <div className={'projects'}>
        <div className={'projects-header'}>
            <h1>Our Portfolio</h1>
        </div>
        <span className={'projects-item'}>
            <CardType
                link={'/portfolio/kitchen'}
                image={'kitchen-2565105_1920.jpg'}
                title={'Kitchens'}
                text={'Bath renovations today in Toronto homes\' and condos are...'}
                btnText={'See photos'}
                large
            />
        </span>

        <span className={'projects-item'}>
            <CardType
                link={'/portfolio/bathroom'}
                image={'bathroom-3563272_1920.jpg'}
                title={'Bathrooms'}
                text={'The bathroom is a place where you want to relax, refresh, and...'}
                btnText={'See photos'}
                large
            />
        </span>

        <span className={'projects-item'}>
            <CardType
                link={'/portfolio/basement'}
                image={'rec-room-802017_1920.jpg'}
                title={'Basements'}
                text={'Almost every home in Toronto has a basement. Unfortunately, many...'}
                btnText={'See photos'}
                large
            />
        </span>

        <span className={'projects-item'}>
            <CardType
                link={'/portfolio/house'}
                image={'living-room-2583032_1920.jpg'}
                title={'Whole House'}
                text={'If you have recently purchased an older home, or require major...'}
                btnText={'See photos'}
                large
            />
        </span>

    </div>
}

export default Projects;