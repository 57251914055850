import React, {useEffect, useState} from "react";
import './styles.css';
import {kitchen} from "../../img/kitchen";
import {bathroom} from "../../img/bathroom";
import {basement} from "../../img/basement";
import {house} from "../../img/house";


const CasePortfolio = (props) => {
    const [currCase, setCurrCase] = useState('');
    const [currPhotos, setCurrPhotos] = useState([])
    const [visible, setVisible] = useState(0);
    const [clicked, setClicked] = useState(null);

    const photos = {
        'kitchen': kitchen,
        'bathroom': bathroom,
        'basement': basement,
        'house': house,
    }


    useEffect(() => {
        setCurrPhotos(photos[currCase]);
    }, [currCase, setCurrPhotos, photos])


    useEffect(() => {
        if (props) {
            if (currCase !== props?.match?.params?.caseId) {
                setCurrCase(props?.match?.params?.caseId);
                setVisible(9);
            }
        }
    }, [props, currCase, setCurrCase, setVisible])

    useEffect(() => {
        window.scroll(0, 0);
    }, [])


    return <div
        className={'case-portfolio'}
        onClick={clicked ? () => setClicked(null) : undefined}
    >
        {currCase?.length > 1 &&
            <div className={'projects-header ' + currCase + '-main-image'}>
                <h1>{currCase[0].toUpperCase() + currCase.substring(1)} Gallery</h1>
            </div>
        }

        <div className={'case-portfolio-container'}>
            {
                currPhotos && currPhotos.slice(0, visible).map((item, index) =>
                    <img src={item} alt={currCase + index} onClick={() => setClicked(item)}/>
                )
            }
        </div>

        <div style={{textAlign: 'center', marginBottom: '4.5vw', marginTop: '2vw'}}>
            {
                (currPhotos && currPhotos.length > 0 && visible < currPhotos.length)
                    ? <button onClick={() => setVisible(visible + 9)} className="learn-more">
                    <span className="circle">
                        <span className="icon arrow-see-more"/>
                    </span>
                        <span className="button-text">See More</span>
                    </button>
                    : null
            }
        </div>

        {clicked &&
            <div className={'case-portfolio-clicked-background'}>
                <img
                    className={'case-portfolio-clicked'}
                    src={clicked}
                    alt={'clicked'}
                    onClick={() => setClicked(null)}
                />
            </div>
        }
    </div>
}

export default CasePortfolio;