import React, {Fragment} from 'react';
import './Contact.css';
import FormContact from "./FormContact/FormContact";

class Contact extends React.Component {
    render() {
        return (
            <Fragment>
                <div className={'contact-header'}>
                    <h1>Contact Us</h1>
                </div>
                <table>
                    <tr>
                        <th className={'left-contact'}>
                            <FormContact/>
                        </th>
                        <th className={'right-contact'}>
                            <h2 style={{fontWeight: 'bolt', marginBottom: '3vh'}}>
                                Telephone Numbers
                            </h2>
                            <h3>
                                +1-647-676-6843
                            </h3>
                            <h3>
                                +1-647-676-6834
                            </h3>

                            <h2 style={{fontWeight: 'bolt', margin:'7vh 0 3vh 0'}}>
                                Email
                            </h2>
                            <h3>
                                asmrenovations.help@gmail.com
                            </h3>

                            <h2 style={{fontWeight: 'bolt', margin:'7vh 0 3vh 0'}}>
                                Hours Of Operation
                            </h2>
                            <h3>
                                Monday - Friday: 9:00AM - 5:00PM
                            </h3>
                            <h3>
                                Saturday - Sunday: By Appointment Only
                            </h3>
                        </th>
                    </tr>
                </table>
            </Fragment>
        );
    }
}

export default Contact;