import React, {Fragment} from 'react';
import {getImg} from "../../../Helper";
import './Basement.css';
import '../../../button.css';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

class Basement extends React.Component {

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        return (
            <Fragment>
                <div className={'basement-header'}>
                    <h1>Basement Renovations</h1>
                </div>
                <img className={'kitchen-title-img'} src={getImg('basement-3.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('basement-6.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('basement-5.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('basement-4.jpg')} alt={'kitchen'}/>
                <div className={'kitchen-text'}>
                    <h1>Your Toronto Basement Renovation Contractor</h1>
                    <p>
                        Are you planning to make repairs in the basement, but do not know where to start? Our company ASM Renovation will come to the rescue. Our specialists can easily handle the renovation of rooms for the gym, cinema, children's playrooms or office space in the basement.
                    </p>
                    <p>Our specialists know what materials to use in different cases. How to ensure the cleanliness, order and durability of the renovated premises.</p>
                </div>
                <table className={'basement-contact'} style={{width: '100%', borderSpacing: '0'}}>
                    <tr>
                        <th>
                            <div className={'left'}/>
                        </th>
                        <th>
                            <div className={'right'}>
                                <h1>It's All About The Details When It Comes To Design.</h1>
                                <h2>Every project needs a properly structured plan that guides the renovation process</h2>
                                <Link className={'not-underlined'} to={`/contact`}>
                                    <Button className={'btn-contact'} variant="outlined" color="primary">
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </th>
                    </tr>
                </table>
                <div className={'kitchen-projects-div'}>
                    <h1>Our Latest Basement Renovation Projects</h1>
                    <img className={'kitchen-projects'} src={getImg('basement-7.jpg')} alt={'kitchen'}/>
                    <img className={'kitchen-projects'} src={getImg('basement-2.jpg')} alt={'kitchen'}/>
                    <img className={'kitchen-projects no-mobile'} src={getImg('basement-1.jpg')} alt={'kitchen'}/>
                    <Link to={'/portfolio'}>
                        <div style={{marginTop: '3vh'}} id="container">
                            <button className="learn-more">
                                                    <span className="circle">
                                                        <span className="icon arrow-see-more"/>
                                                    </span>
                                <span className="button-text">See More</span>
                            </button>
                        </div>
                    </Link>
                </div>
            </Fragment>
        );
    }
}

export default Basement;