import * as React from 'react';
import {Fragment} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import HomePage from "./components/HomePage/HomePage";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Menu from "./components/HomePage/Menu/Menu";
import Kitchen from "./components/Services/Kitchen/Kitchen";
import Bath from "./components/Services/Bath/Bath";
import Basement from "./components/Services/Basement/Basement";
import House from "./components/Services/House/House";
import CasePortfolio from "./components/CasePortfolio";

function App() {
  return (
      <BrowserRouter>
          <Menu/>
        <Fragment>
          <Switch>
            <Route path='/' component={HomePage} exact={true}/>
            <Route path='/about' component={Contact} exact={true}/>
            <Route path='/portfolio' component={Projects} exact={true}/>
            <Route path='/portfolio/:caseId' component={CasePortfolio} exact={true}/>
            <Route path='/kitchen-renovations' component={Kitchen} exact={true}/>
            <Route path='/bathroom-renovations' component={Bath} exact={true}/>
            <Route path='/basement-renovations' component={Basement} exact={true}/>
            <Route path='/house-renovations' component={House} exact={true}/>
            <Route path='/contact' component={Contact} exact={true}/>
            <Redirect to='/'/>
          </Switch>
        </Fragment>
          <Footer/>
      </BrowserRouter>
  );
}

export default App;
