import React, {Fragment} from 'react';
import './Footer.css'
import {Link} from "react-router-dom";

class Footer extends React.Component {
    render() {
        return (
            <Fragment>
                <footer id={'footer row'}>
                        <div className={'col col-33 footer-1'}>
                            <h2 className={'footer-title'}>About</h2>
                            <p>ASM Renovations has offered an innovative and professional approach to home renovations. From kitchens and baths to whole house projects, we can transform any room in your home.</p>
                        </div>

                        <div className={'col col-33 footer-2'}>
                            <h2 className={'footer-title'}>Sources</h2>
                            <div>
                                <Link to={'/kitchen-renovations'} className={'footer-link'}>+ Kitchens Renovations</Link>
                                <Link to={'/bathroom-renovations'} className={'footer-link'}>+ Bathrooms Renovations</Link>
                                <Link to={'/basement-renovations'} className={'footer-link'}>+ Basements Renovations</Link>
                                <Link to={'/house-renovations'} className={'footer-link'}>+ Whole House Renovations</Link>
                            </div>
                        </div>

                        <div className={'col col-33 footer-3'}>
                            <h2 className={'footer-title'}>Get In Touch</h2>
                            <div className={'info'}>
                                <p>Phone Number: <strong>+1-647-676-6843</strong></p>
                                <p>E-main: <strong>asmrenovations.help@gmail.com</strong></p>
                            </div>

                        </div>
                </footer>
                <div className={'footer-bottom row'}>
                    © 2020 ASM Renovations | <span style={{color: '#ffffff'}}> All rights reserved → </span>
                </div>
            </Fragment>
        );
    }
}

export default Footer;

