import Img1 from './kitchen/9-web-or-mls-DSC06467.jpg';
import Img2 from './kitchen/10-web-or-mls-DSC06532.jpg';
import Img3 from './kitchen/12-web-or-mls-DSC06552.jpg';
import Img4 from './kitchen/13-web-or-mls-DSC06565.jpg';
import Img5 from './kitchen/16-web-or-mls-DSC06472.jpg';
import Img6 from './kitchen/web_1-15.jpg';
import Img7 from './kitchen/web_extra-4.jpg';
import Img8 from './kitchen/W5390484_5.jpg';
import Img9 from './kitchen/W5390484_6.jpg';
import Img10 from './kitchen/W5390484_7.jpg';
import Img11 from './kitchen/W5390484_8.jpg';
import Img12 from './kitchen/W5390484_16.jpg';
import Img13 from './kitchen/W5390484_14.jpg';
import Img14 from './kitchen/IMG_0289.JPG';
import Img15 from './kitchen/IMG_0316.JPG';
import Img16 from './kitchen/IMG_0299.JPG';

export const kitchen = [
    Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11, Img12, Img13, Img14, Img15, Img16
]