import Img1 from './house/4-web-or-mls-DSC06420.jpg';
import Img2 from './house/5-web-or-mls-DSC06980.jpg';
import Img3 from './house/8-web-or-mls-DSC06462.jpg';
import Img4 from './house/17-web-or-mls-DSC06480.jpg';
import Img5 from './house/22-web-or-mls-DSC06435.jpg';
import Img6 from './house/25-web-or-mls-DSC06642.jpg';
import Img7 from './house/26-web-or-mls-DSC06647.jpg';
import Img8 from './house/27-web-or-mls-DSC06675.jpg';
import Img9 from './house/29-web-or-mls-DSC06727.jpg';
import Img10 from './house/30-web-or-mls-DSC06757.jpg';
import Img11 from './house/31-web-or-mls-DSC06762.jpg';
import Img12 from './house/32-web-or-mls-DSC06780.jpg';
import Img13 from './house/34-web-or-mls-DSC06817.jpg';
import Img14 from './house/38-web-or-mls-DSC06852.jpg';
import Img15 from './house/40-web-or-mls-DSC06857.jpg';
import Img16 from './house/41-web-or-mls-DSC06882.jpg';
import Img17 from './house/48-web-or-mls-DJI_0139.jpg';
import Img18 from './house/W5390484.jpg';
import Img19 from './house/W5390484_2.jpg';
import Img20 from './house/W5390484_3.jpg';
import Img21 from './house/W5390484_4.jpg';
import Img22 from './house/W5390484_10.jpg';
import Img23 from './house/W5390484_11.jpg';
import Img24 from './house/W5390484_12.jpg';
import Img25 from './house/W5390484_13.jpg';
import Img26 from './house/W5390484_15.jpg';
import Img28 from './house/W5390484_17.jpg';
import Img29 from './house/W5390484_18.jpg';
import Img30 from './house/W5390484_20.jpg';
import Img31 from './house/W5390484_21.jpg';
import Img32 from './house/W5390484_24.jpg';
import Img33 from './house/W5390484_25.jpg';
import Img34 from './house/W5390484_26.jpg';
import Img35 from './house/W5390484_29.jpg';
import Img36 from './house/W5390484_31.jpg';
import Img37 from './house/W5390484_39.jpg';
import Img38 from './house/W5390484_40.jpg';
import Img39 from './house/IMG_0256.JPG';
import Img40 from './house/IMG_0264.JPG';
import Img41 from './house/IMG_0265.JPG';
import Img42 from './house/IMG_0266.JPG';
import Img43 from './house/IMG_0286.JPG';
import Img44 from './house/IMG_0287.JPG';
import Img45 from './house/IMG_0288.JPG';
import Img46 from './house/IMG_0290.JPG';
import Img47 from './house/IMG_0292.JPG';
import Img48 from './house/IMG_0294.JPG';
import Img49 from './house/IMG_0302.JPG';
import Img50 from './house/IMG_0326.JPG';
import Img51 from './house/IMG_0327.JPG';
import Img52 from './house/IMG_0328.JPG';
import Img53 from './house/IMG_0329.JPG';
import Img54 from './house/IMG_0331.JPG';
import Img55 from './house/IMG_0332.JPG';
import Img56 from './house/IMG_0333.JPG';
import Img57 from './house/IMG_0334.JPG';
import Img58 from './house/IMG_0335.JPG';
import Img59 from './house/IMG_0336.JPG';
import Img60 from './house/IMG_0337.JPG';
import Img61 from './house/IMG_0338.JPG';
import Img62 from './house/IMG_0339.JPG';
import Img63 from './house/IMG_0340.JPG';
import Img64 from './house/IMG_0341.JPG';
import Img65 from './house/IMG_0342.JPG';
import Img66 from './house/IMG_0344.JPG';
import Img67 from './house/IMG_0346.JPG';
import Img68 from './house/IMG_0348.JPG';
import Img69 from './house/IMG_0349.JPG';
import Img70 from './house/IMG_0350.JPG';
import Img71 from './house/IMG_0351.JPG';
import Img72 from './house/IMG_0352.JPG';
import Img73 from './house/IMG_0383.JPG';
import Img74 from './house/IMG_0384.JPG';
import Img75 from './house/IMG_0385.JPG';
import Img76 from './house/IMG_0387.JPG';
import Img77 from './house/IMG_0388.JPG';
import Img78 from './house/IMG_0389.JPG';
import Img79 from './house/IMG_0390.JPG';
import Img80 from './house/IMG_0393.JPG';
import Img81 from './house/IMG_0394.JPG';
import Img82 from './house/IMG_0395.JPG';
import Img83 from './house/3-web-or-mls-DSC06960.jpg';
import Img84 from './house/1-web-or-mls-DSC06995.jpg';



export const house = [
    Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11,
    Img12, Img13, Img14, Img15, Img16, Img17, Img18, Img19,
    Img20, Img21, Img22, Img23, Img24, Img25, Img26, Img28, Img29,
    Img30, Img31, Img32, Img33, Img34, Img35, Img36, Img37, Img38, Img39,
    Img40, Img41, Img42, Img43, Img44, Img45, Img46, Img47, Img48, Img49,
    Img50, Img51, Img52, Img53, Img54, Img55, Img56, Img57, Img58, Img59,
    Img60, Img61, Img62, Img63, Img64, Img65, Img66, Img67, Img68, Img69,
    Img70, Img71, Img72, Img73, Img74, Img75, Img76, Img77, Img78, Img79,
    Img80, Img81, Img82, Img83, Img84, Img1, Img2
]