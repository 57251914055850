import React, {Fragment} from 'react';
import {getImg} from "../../../Helper";
import './House.css';
import '../../../button.css';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

class House extends React.Component {
    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <Fragment>
                <div className={'house-header'}>
                    <h1>Whole House Renovations</h1>
                </div>
                <img className={'kitchen-title-img'} src={getImg('house-4.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('house-7.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('house-6.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('house-5.jpg')} alt={'kitchen'}/>
                <div className={'kitchen-text'}>
                    <h1>Your Toronto Whole House Renovation Contractor</h1>
                    <p>
                        Today there are many different options and directions in home design, each of which offers completely different ideas and implementations. Stereotypes are a thing of the past, and now everyone has the opportunity to renovate their homes in accordance with modern trends.
                    </p>
                    <p>To choose the right style and decoration of housing, you must first rely on their own preferences and desires. But our company ASM Renovation advises to take into account the features of the premises, so that the chosen style looks harmonious and not a mess. To do this, it is important to be able to thoroughly understand the features of the construction craft, have experience in this field and know how to properly apply certain modern building materials. This can only be done by professional organizations, such as ASM Renovation.</p>
                </div>
                <table className={'house-contact'} style={{width: '100%', borderSpacing: '0'}}>
                    <tr>
                        <th>
                            <div className={'left'}/>
                        </th>
                        <th>
                            <div className={'right'}>
                                <h1>It's All About The Details When It Comes To Design.</h1>
                                <h2>Every project needs a properly structured plan that guides the renovation process</h2>
                                <Link className={'not-underlined'} to={`/contact`}>
                                    <Button className={'btn-contact'} variant="outlined" color="primary">
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </th>
                    </tr>
                </table>
                <div className={'kitchen-projects-div'}>
                    <h1>Our Latest Whole House Renovation Projects</h1>
                    <img className={'kitchen-projects'} src={getImg('house-1.png')} alt={'kitchen'}/>
                    <img className={'kitchen-projects'} src={getImg('house-3.png')} alt={'kitchen'}/>
                    <img className={'kitchen-projects no-mobile'} src={getImg('house-2.png')} alt={'kitchen'}/>
                    <Link to={'/portfolio'}>
                        <div style={{marginTop: '3vh'}} id="container">
                            <button className="learn-more">
                                                    <span className="circle">
                                                        <span className="icon arrow-see-more"/>
                                                    </span>
                                <span className="button-text">See More</span>
                            </button>
                        </div>
                    </Link>
                </div>
            </Fragment>
        );
    }
}

export default House;