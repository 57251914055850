import React, {Fragment} from 'react';
import './FormContact.css';

class FormContact extends React.Component {
    componentDidMount() {
        const setActive = (el, active) => {
            const formField = el.parentNode.parentNode
            if (active) {
                formField.classList.add('form-field--is-active')
            } else {
                formField.classList.remove('form-field--is-active')
                el.value === '' ?
                    formField.classList.remove('form-field--is-filled') :
                    formField.classList.add('form-field--is-filled')
            }
        }

        [].forEach.call(
            document.querySelectorAll('.form-field__input, .form-field__textarea'),
            (el) => {
                el.onblur = () => {
                    setActive(el, false)
                }
                el.onfocus = () => {
                    setActive(el, true)
                }
            }
        )

    }

    sendEmail() {
        let data = {
            service_id: 'service_qgzi3lg',
            template_id: 'template_lfy5o1w',
            user_id: '_PdTCNGQCatbZ_R_9',
            template_params: {}
        };
        data.template_params.name = document.getElementById('firstname').value;
        data.template_params.last_name = document.getElementById('lastname').value;
        data.template_params.email = document.getElementById('email').value;
        data.template_params.phone = document.getElementById('tel').value;
        data.template_params.message = document.getElementById('additionalInfo').value;

        fetch("https://api.emailjs.com/api/v1.0/email/send", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json"
          }
        }).then(() => alert('sent')).then(() => window.location.reload());
    }

    render() {
        return (
            <Fragment>
                <h1>Get In Touch</h1>
                <div className="container" style={{ backgroundColor: '#ffffff'}}>
                    <form noValidate>
                        <div className="row">
                            <table>
                                <tr>
                                    <th style={{width: '300px'}}>
                                        <div className="col-sm">
                                            <div className="form-field">
                                                <div className="form-field__control">
                                                    <label htmlFor="firstname" className="form-field__label">First name</label>
                                                    <input id="firstname" type="text" className="form-field__input"/>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <th style={{width: '300px'}}>
                                        <div className="col-sm">
                                            <div className="form-field">
                                                <div className="form-field__control">
                                                    <label htmlFor="lastname" className="form-field__label">Last name</label>
                                                    <input id="lastname" type="text" className="form-field__input"/>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </table>


                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-field">
                                    <div className="form-field__control">
                                        <label htmlFor="email" className="form-field__label">Email</label>
                                        <input id="email" type="email" className="form-field__input"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-field">
                                    <div className="form-field__control">
                                        <label htmlFor="tel" className="form-field__label">Phone</label>
                                        <input id="tel" type="tel" className="form-field__input"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-field">
                                    <div className="form-field__control">
                                        <label htmlFor="additionalInfo" className="form-field__label">Message</label>
                                        <textarea id="additionalInfo" className="form-field__textarea"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input onClick={this.sendEmail} className={'submit-btn'} value="SUBMIT"/>
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default FormContact;