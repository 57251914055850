import React, {Fragment} from 'react';
import {getImg} from "../../../Helper";
import './Kitchen.css';
import '../../../button.css';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

class Kitchen extends React.Component {
    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <Fragment>
                <div className={'kitchen-header'}>
                    <h1>Kitchen Renovations</h1>
                </div>
                <img className={'kitchen-title-img'} src={getImg('kitchen-2.png')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('kitchen-1.png')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('kitchen-4.png')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('kitchen-3.png')} alt={'kitchen'}/>
                <div className={'kitchen-text'}>
                    <h1>Your Toronto Kitchen Renovation Contractor</h1>
                    <p>
                        The world of design is moving very fast, old trends are giving way to new trends. For several years, the main trends in kitchen design remain unchanged - in fashion accessories made of environmentally friendly materials that combine practicality and comfort. But the renovation of the room requires not only knowledge but also an understanding that the kitchen is now not only a place for cooking, but also a meeting of the whole family at the same table, communication and fun. Therefore, kitchen renovation should be modern, comfortable and pleasing to the eye.
                    </p>
                    <p>Our company will be able to provide everything to you. Our employees know how to do it. We can create kitchens for you in calm and bright colors. We can combine different materials: wood, stone and granite. We offer to create comfort with the help of wooden interspersed in the interior. We will do everything if only you liked it and it was comfortable.</p>
                </div>
                <table className={'kitchen-contact'} style={{width: '100%', borderSpacing: '0'}}>
                    <tr>
                        <th>
                            <div className={'left'}/>
                        </th>
                        <th>
                            <div className={'right'}>
                                <h1>It's All About The Details When It Comes To Design.</h1>
                                <h2>Every project needs a properly structured plan that guides the renovation process</h2>
                                <Link className={'not-underlined'} to={`/contact`}>
                                    <Button className={'btn-contact'} variant="outlined" color="primary">
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </th>
                    </tr>
                </table>
                <div className={'kitchen-projects-div'}>
                    <h1>Our Latest Kitchen Renovation Projects</h1>
                    <img className={'kitchen-projects'} src={getImg('kitchen-more1.jpg')} alt={'kitchen'}/>
                    <img className={'kitchen-projects'} src={getImg('kitchen-more2.jpg')} alt={'kitchen'}/>
                    <img className={'kitchen-projects no-mobile'} src={getImg('kitchen-more3.jpg')} alt={'kitchen'}/>
                    <Link to={'/portfolio'}>
                        <div style={{marginTop: '3vh'}} id="container">
                            <button className="learn-more">
                                                    <span className="circle">
                                                        <span className="icon arrow-see-more"/>
                                                    </span>
                                <span className="button-text">See More</span>
                            </button>
                        </div>
                    </Link>
                </div>
            </Fragment>
        );
    }
}

export default Kitchen;