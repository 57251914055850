import React, {Fragment} from 'react';
import {getImg} from "../../../Helper";
import './Bath.css';
import '../../../button.css';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

class Bath extends React.Component {
    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <Fragment>
                <div className={'bath-header'}>
                    <h1>Bathroom Renovations</h1>
                </div>
                <img className={'kitchen-title-img'} src={getImg('bath-1.png')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('bathroom-3.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('bathroom-4.jpg')} alt={'kitchen'}/>
                <img className={'kitchen-title-img'} src={getImg('bathroom-7.jpg')} alt={'kitchen'}/>
                <div className={'kitchen-text'}>
                    <h1>Your Toronto Bathroom Renovation Contractor</h1>
                    <p>
                        We understand that our customers are well aware of what interior design is and they strive to follow the fashion trends in the design of each part of their home. Therefore, our company ASM renovation is ready to work for you in the fashion industry and perform not only visually beautiful, but also all your functional solutions. Our employees know about all the features of bathroom design in a modern style, we understand how to maintain a classic style, and we know how to combine for you drywall, tiles, a variety of materials for the ceiling and floor.
                    </p>
                    <p>We know when to choose a matte ceiling covering, when to glossy, when to finish the walls with an even coating, and when to use decorated tiles. We can make not only a new bathroom, but also we can restore an old bathroom so that you will feel in it absolutely on new: it is convenient, comfortable and pleasant.</p>
                </div>
                <table className={'bath-contact'} style={{width: '100%', borderSpacing: '0'}}>
                    <tr>
                        <th>
                            <div className={'left'}/>
                        </th>
                        <th>
                            <div className={'right'}>
                                <h1>It's All About The Details When It Comes To Design.</h1>
                                <h2>Every project needs a properly structured plan that guides the renovation process</h2>
                                <Link className={'not-underlined'} to={`/contact`}>
                                    <Button className={'btn-contact'} variant="outlined" color="primary">
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </th>
                    </tr>
                </table>
                <div className={'kitchen-projects-div'}>
                    <h1>Our Latest Bathroom Renovation Projects</h1>
                    <img className={'kitchen-projects'} src={getImg('bathroom-5.jpg')} alt={'kitchen'}/>
                    <img className={'kitchen-projects'} src={getImg('bathroom-6.jpg')} alt={'kitchen'}/>
                    <img className={'kitchen-projects no-mobile'} src={getImg('bath-2.png')} alt={'kitchen'}/>
                    <Link to={'/portfolio'}>
                        <div style={{marginTop: '3vh'}} id="container">
                            <button className="learn-more">
                                                    <span className="circle">
                                                        <span className="icon arrow-see-more"/>
                                                    </span>
                                <span className="button-text">See More</span>
                            </button>
                        </div>
                    </Link>
                </div>
            </Fragment>
        );
    }
}

export default Bath;