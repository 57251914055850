import React, {Fragment} from 'react';
import './HomePage.css'
import CardType from "../CardType/CardType";

class HomePage extends React.Component {
    render() {
        return (
            <Fragment>
                <div className={'homepage-header'} />
                <CardType link={'/kitchen-renovations'} image={'kitchen-2565105_1920.jpg'} title={'Kitchens'} text={'Bath renovations today in Toronto homes\' and condos are...'}/>
                <CardType link={'/bathroom-renovations'} image={'bathroom-3563272_1920.jpg'} title={'Bathrooms'} text={'The bathroom is a place where you want to relax, refresh, and...'}/>
                <CardType link={'/basement-renovations'} image={'rec-room-802017_1920.jpg'} title={'Basements'} text={'Almost every home in Toronto has a basement. Unfortunately, many...'}/>
                <CardType link={'/house-renovations'} image={'living-room-2583032_1920.jpg'} title={'Whole House'} text={'If you have recently purchased an older home, or require major...'}/>
            </Fragment>
        );
    }
}

export default HomePage;